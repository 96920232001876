.card{
    width: 400px;
    /* height: 214px; */
    background-color: #FEFEFE;
    padding: 15px;
    margin: 5px;
    border-radius: 12px;
}

.card_list{
    display: flex;
    align-items: center;
    line-height: 0px;
}

.card .card_box .card_item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px !important;
}

.status{
    background-color: red;
    border-radius: 8px;
    color: #fff;
    padding: 12px 10px;
    margin-right: 15px;
}

.card_box h3{
    font-weight: bold;
}
