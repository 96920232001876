body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.callector{
  background-color: #F8F8F8;
  width: 100%;
  height: 100vh;
  background-image: url("./Assets/Images/rectangle.png");
  background-repeat: no-repeat;
  background-size: 1129px;
  /* transform: translate( 0% , 0%); */
  padding: 0.1px;

}

.container{
  width: 1180px;
  margin: 0 auto;
  padding: 0px 20px;
}

.card_border{
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

@media(max-width:1120px){
  .container{
    width: 1020px;
  }
}

@media(max-width:1020px){
  .container{
    width: 920px;
  }
}

@media(max-width:920px){
  .container{
    width: 820px;
  }
}

@media(max-width:820px){
  .container{
    width: 720px;
  }
}

@media(max-width:720px){
  .container{
    width: 680px;
  }
}

@media(max-width:680px){
  .container{
    width: 610px;
  }
}

@media(max-width:610px){
  .container{
    width: 570px;
  }
}

@media(max-width:570px){
  .container{
    width: 510px;
  }
}

@media(max-width:510px){
  .container{
    width: 480px;
  }
}

@media(max-width:480px){
  .container{
    width: 410px;
  }
}

@media(max-width:410px){
  .container{
    width: 380px;
  }
}

@media(max-width:380px){
  .container{
    width: 350px;
  }
}

@media(max-width:350px){
  .container{
    width: 300px;
  }
}