

.title_box{
    /* padding-top: 30px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FEFEFE;
}

.title_p{
    color: #6200EE;
}

.creacte_btn_box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #6200EE;
    color: #FEFEFE;
    font-size: 16px;
    width: 181px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 4px;
}


.modal_btn{
    margin-top: 10px;
}

